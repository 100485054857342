import { NumberInputProps, TextInput } from 'react-admin';
import { InputAdornment } from '@mui/material';

const PercentNumberInput = (props: NumberInputProps) => {
  return (
    <TextInput type="number"
      step={0.01} min={0} max={100} sx={{ width: '16em' }}
      {...props}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};

export default PercentNumberInput;
