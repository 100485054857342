import { useEffect, useState } from 'react';
import { MenuItemLink, useTranslate, useSidebarState, usePermissions } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import RewardsIcon from '@mui/icons-material/CurrencyYen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BarChartIcon from '@mui/icons-material/BarChart';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getLocalStorageId, hasPermission } from './authProvider';
import { ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from './common_modules/constants/define';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import dataProvider from './dataProvider';
import { useChangedIdListCount } from './common_modules/hooks/ChangedIdListCount';

interface CustomMenuProps {
  dense?: boolean;
}

const CustomMenu = ({ dense = false }: CustomMenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();
  const [isCompany, setIsCompany] = useState(false);
  const { changedIdListCount, setChangedIdListCount } = useChangedIdListCount();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedItems = localStorage.getItem('currentItems');
        let items;

        if (savedItems) {
          items = JSON.parse(savedItems);
        } else {
          items = [
            {
              clientId: 0,
              url: 'https://example.com'
            }
          ];
        }

        const response = await dataProvider.customPost('affiliate-urls/count', { items });
        console.log(response);

        if (response && response.data && response.data.currentItems) {
          if (!savedItems) {
            localStorage.setItem('currentItems', JSON.stringify(response.data.currentItems));
          }
        }

        const changedIdList = response?.data?.changedIdList?.length ?? 0;
        setChangedIdListCount(changedIdList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  });

  useEffect(() => {
    const storedIsCompany = localStorage.getItem('isCompany');
    if (storedIsCompany) {
      setIsCompany(storedIsCompany === 'true');
    }
  }, []);

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        height: 'calc(100vh - env(safe-area-inset-bottom))',
        marginTop: 1,
        marginBottom: 1,
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
      }}
    >
      {hasPermission(permissions, ROLE_ADMIN) && (
        <MenuItemLink
          to="/accounts"
          primaryText={translate(`resources.accounts.name`)}
          leftIcon={<UserIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_EDIT) && (
        <MenuItemLink
          to="/affiliates"
          primaryText={translate(`resources.affiliates.name`)}
          leftIcon={<LoyaltyIcon />}
          dense={dense}
        >
          {changedIdListCount > 0 && (
            <Badge badgeContent={changedIdListCount} color="primary" sx={{ '& .MuiBadge-badge': { right: '-8px' } }}>
              {translate(`resources.affiliates.name`)}
            </Badge>
          )}
        </MenuItemLink>
      )}
      <MenuItemLink
        to="/analytics"
        primaryText={translate(`resources.analytics.name.analytics`)}
        leftIcon={<BarChartIcon />}
        dense={dense}
      />
      {hasPermission(permissions, ROLE_SALES) && (
        <MenuItemLink
          to="/rewards"
          primaryText={translate(`resources.rewards.name`)}
          leftIcon={<RewardsIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_EDIT) && isCompany && (
        <MenuItemLink
          to="/invitations"
          primaryText={translate(`resources.invitations.name`)}
          leftIcon={<MailOutlineIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_ADMIN) && (
        <MenuItemLink
          to={`/settings/${getLocalStorageId()}`}
          primaryText={translate(`resources.settings.name`)}
          leftIcon={<SettingsIcon />}
          dense={dense}
        />
      )}
    </Box>
  );
};

export default CustomMenu;
