import React, { useEffect, useState } from 'react';
import { Container, Box, Stack, useMediaQuery, Theme, Typography } from '@mui/material';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

const Step: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string | null>('');
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const sxMargin = isSmall
    ? {
        mt: 5,
        mb: 5,
        ml: 2,
        mr: 2
      }
    : {
        mt: 10,
        mb: 10,
        ml: 3,
        mr: 3
      };

  const LayoutContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  });

  const ContentContainer = styled(Box)({
    flex: '1 0 auto'
  });

  function isiOSorIPadOS17Later() {
    try {
      var ua = navigator.userAgent;
      var iOS = /iPhone|iPod/.test(ua);
      var iPadOS = /iPad/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      if (iOS || iPadOS) {
        var versionMatch = ua.match(/OS (\d+)_/) || ua.match(/Version\/(\d+)\./);
        var isVersion17OrHigher = false;
        if (versionMatch && versionMatch.length > 1) {
          var version = parseInt(versionMatch[1], 10);
          isVersion17OrHigher = version >= 17;
        }
        return isVersion17OrHigher;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  useEffect(() => {
    const hash = location.hash;
    const queryString = hash.split('?')[1];

    var urlParams = new URLSearchParams(queryString);
    var affi_id = urlParams.get('affi_id');
    var url = urlParams.get('site');
    if (affi_id) {
      url += '?affi_id=' + affi_id;
    }
    if (url) {
      if (!isiOSorIPadOS17Later()) {
        window.location.replace(url);
      } else {
        setLoading(false);
        setUrl(url);
      }
    } else {
      setLoading(false);
    }
  }, []);

  const handleClick = () => {
    window.open('x-safari-' + url, '_blank');
  };

  const gap = isSmallScreen ? 3 : 5;
  return !loading ? (
    <LayoutContainer
      sx={{
        '& .MuiContainer-root': {
          padding: 0
        }
      }}
    >
      <ContentContainer sx={sxMargin}>
        <Container>
          <Helmet>
            <title>BEYOND SWEETS</title>
          </Helmet>

          <Stack direction={'column'} spacing={gap} alignItems={'center'}>
            <Typography variant="h4">BEYOND SWEETS</Typography>

            <Stack alignItems="center" spacing={3}>
              {url ? (
                <>
                  <Typography variant="body1">
                    BEYOND SWEETSのWEBサイトに移動します。 以下のボタンから、Safariで開くを選択してください。
                  </Typography>
                  <Stack alignItems="center" spacing={3} mt={5}>
                    <RegisterSubmitButton style={{ textTransform: 'none' }} onClick={handleClick}>
                      Safariで開く
                    </RegisterSubmitButton>
                  </Stack>
                </>
              ) : (
                <Typography variant="body1">無効なURLです。</Typography>
              )}
            </Stack>
          </Stack>
        </Container>
      </ContentContainer>
    </LayoutContainer>
  ) : (
    <Box />
  );
};

export default Step;
