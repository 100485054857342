import { createContext, useContext, useState, ReactNode } from 'react';

interface ChangedIdListCountProps {
  changedIdListCount: number;
  setChangedIdListCount: (count: number) => void;
}

const ChangedIdListContext = createContext<ChangedIdListCountProps | undefined>(undefined);

export const ChangedIdListProvider = ({ children }: { children: ReactNode }) => {
  const [changedIdListCount, setChangedIdListCount] = useState<number>(0);

  return (
    <ChangedIdListContext.Provider value={{ changedIdListCount, setChangedIdListCount }}>
      {children}
    </ChangedIdListContext.Provider>
  );
};

export const useChangedIdListCount = () => {
  const context = useContext(ChangedIdListContext);
  if (!context) {
    throw new Error('useChangedIdListContext must be used within a ChangedIdListProvider');
  }
  return context;
};
