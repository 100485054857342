import { i18nProvider } from '../../App';

import { Exporter, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { formatRFC3339DateString } from './dateutils';

export const defaultExporter: Exporter = async (data, fetchRelatedRecords, dataProvider, resource) => {
  const translate = i18nProvider.translate;

  // エクスポートするカラムの配列を準備（除外するカラムを除く）
  // const exportColumns = Object.keys(data[0]).filter(
  //     key => !excludedColumns.includes(key)
  // );

  // ヘッダーを準備（翻訳IDを動的に生成）
  const renameHeaders = Object.keys(data[0]).map((key) => {
    const n = translate(`resources.${resource}.fields.${key}`, { _: key });
    return n;
  });

  // データの変換処理
  const dataForExport = data.map((record: { [x: string]: any }) => {
    const newData: { [key: string]: any } = {};
    for (const key in record) {
      switch (key) {
        case 'available': // 例: 'available'カラムの場合の変換処理
          newData[key] = record[key] ? '有効' : '無効';
          break;

        case 'createdAt':
        case 'updatedAt':
        case 'loginDateTime':
          newData[key] = formatRFC3339DateString(record[key]);
          break;

        default:
          newData[key] = record[key]; // その他のカラムは変換せずにそのまま使用
          break;
      }
    }
    return newData;
  });

  // 変換されたデータをCSV形式に変換してダウンロード
  jsonExport(dataForExport, { rename: renameHeaders }, (err: Error, csv: string) => {
    if (err) {
      console.error(err);
      return;
    }
    downloadCSV(csv, `${resource}-export`);
  });
};
