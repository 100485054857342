import { Theme } from '@mui/material';
import { RaThemeOptions } from 'react-admin';
import { responsiveFontSizes } from '@mui/material/styles';

let baseTheme = {
  typography: {
    fontFamily: 'Noto Sans JP'
  },

  palette: {
    primary: {
      main: '#5B6955'
    },
    secondary: {
      main: '#9FB694',
      contrastText: '#ffffff'
    }
  },
  sidebar: {
    width: 240,
    closedWidth: 50
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true
      }
    },
    MuiAutocomplete: {
      variants: [
        {
          props: {},
          style: ({ theme }: { theme: any }) => ({
            [theme.breakpoints.down('sm')]: { width: '100%' }
          })
        }
      ]
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense' as const,
        size: 'small' as const
      },
      variants: [
        {
          props: {},
          style: ({ theme }: { theme: any }) => ({
            [theme.breakpoints.down('sm')]: { width: '100%' }
          })
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '4px'
        },
        notchedOutline: {
          borderWidth: '2px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          margin: '4px'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense' as const,
        size: 'small' as const
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          '& .MuiTableSortLabel-icon': {
            order: 2 // ソートアイコンの順序を変更
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'visible'
        }
      }
    }
  }
} as RaThemeOptions;

export function baseResponsiveFontSizes(themeInput: Theme) {
  const responsivedTheme = responsiveFontSizes(themeInput);

  const theme = { ...responsivedTheme };
  theme.typography = {
    ...theme.typography,
    body1: {
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14)
      }
    }
  };
  return theme;
}

export default baseTheme;
