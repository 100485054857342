import React from 'react';
import { TextField, useTranslate, FieldProps, useRecordContext } from 'react-admin';

interface EnumTextFieldProps extends FieldProps {
  source: string;
  translationKeyPrefix: string;
}

const EnumTextField: React.FC<EnumTextFieldProps> = (props) => {
  const { source, translationKeyPrefix, ...rest } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();

  if (!record) return null;

  const statusKey = `${translationKeyPrefix}.${record[source]}`;
  const translatedText = translate(statusKey);
  return <TextField {...rest} source={source} record={{ ...record, [source]: translatedText }} />;
};

export default EnumTextField;
