import { useNotify } from 'react-admin';

const useErrorHandler = (): ((error?: any) => void) => {
  const notify = useNotify();

  const handleError = (error?: any) => {
    const message =
      error?.body?.message === 'Bad Request'
        ? '入力値に問題があります。'
        : error?.body?.message || '入力値に問題があります。';

    notify(message, { type: 'error' });

    setTimeout(() => {
      const firstErrorField = document.querySelector('.Mui-error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }, 50);
  };

  return handleError;
};

export default useErrorHandler;
