import { createTheme } from '@mui/material/styles';
import baseTheme, { baseResponsiveFontSizes } from './common_modules/theme/BaseTheme';

let theme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#5B6955'
    },
    secondary: {
      main: '#9FB694',
      contrastText: '#ffffff'
    }
  }
});
theme = baseResponsiveFontSizes(theme);

export default theme;
