/* eslint-disable no-unused-vars */
import React, { useState, ChangeEvent, useRef } from 'react';
import { Button, IconButton, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileInputProps, InputHelperText, InputProps, Labeled, useInput } from 'react-admin';
import FormHelperText from '@mui/material/FormHelperText';

interface FileUploadInputProps extends FileInputProps {}

const FileUploadInput: React.FC<FileUploadInputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // turn a browser dropped file structure into expected structure
  const transformFile = (file: Blob | MediaSource) => {
    if (!(file instanceof File)) {
      return file;
    }

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      src: preview,
      title: file.name
    };

    return transformedFile;
  };

  const transformFiles = (files: any[]) => {
    if (!files) {
      return multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  const {
    accept,
    children,
    className,
    format,
    helperText,
    inputProps: inputPropsOptions,
    maxSize,
    minSize,
    multiple = false,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    options = {},
    onRemove: onRemoveProp,
    parse,
    placeholder,
    removeIcon,
    resource,
    source,
    validate,
    validateFileRemoval,
    disabled,
    readOnly,
    ...rest
  } = props;

  const {
    id,
    field: { onChange, onBlur, value },
    fieldState,
    formState: { isSubmitted },
    isRequired
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    validate,
    disabled,
    readOnly,
    ...rest
  });

  const { isTouched, error, invalid } = fieldState;
  const renderHelperText = helperText !== false || ((isTouched || isSubmitted) && invalid);

  const [fileName, setFileName] = useState<string>('');

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      onChange(file);
    }
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const handleFileDelete = () => {
    setFileName('');
    onChange(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!inputRef || !inputRef.current) return;

    inputRef.current.click();
  }

  return (
    <Box sx={props.sx}>
      <input
        type="file"
        id={id}
        name={id}
        style={{ display: 'none' }}
        accept={accept as string}
        ref={inputRef}
        onChange={handleFileUpload}
      />
      <Labeled
        htmlFor="file-upload"
        label={label}
        source={source}
        isRequired={isRequired}
        color={(isTouched || isSubmitted) && invalid ? 'error' : undefined}
        sx={{
          cursor: disabled || readOnly ? 'default' : 'pointer'
        }}
      >
        <>
          <Box display="flex" flexDirection={'row'} gap={2}>
            <Button
              variant="contained"
              component="span"
              color="secondary"
              sx={{ pl: 2, pr: 2, height: '48px' }}
              onClick={handleButtonClick}
            >
              ファイルを選択
            </Button>
            {fileName && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Typography>{fileName}</Typography>
                <IconButton aria-label="delete" onClick={handleFileDelete}>
                  <DeleteIcon color="error" />
                </IconButton>
              </div>
            )}
          </Box>
        </>
      </Labeled>
      {renderHelperText ? (
        <FormHelperText error={(isTouched || isSubmitted) && invalid}>
          <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default FileUploadInput;
