import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  Show,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate
} from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import { defaultExporter } from '../common_modules/utils/exporter';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import YenField from '../common_modules/components/YenField';
import { format } from 'date-fns';

const ListActions = () => <TopToolbar></TopToolbar>;

export const RewardsList = () => {
  const translate = useTranslate();
  return (
    <List actions={<ListActions />} exporter={defaultExporter}>
      <FlexibleListLayout bulkActionButtons={false} rowClick="show">
        <DateField showtime={true} source="paymentDate" label={translate('resources.rewards.fields.paymentDate')} />
        <FunctionField
          label={translate('resources.rewards.fields.rewardPeriod')}
          render={(record: { rewardStartedAt: string; rewardEndedat: string }) =>
            `${new Date(record.rewardStartedAt).toLocaleDateString()} ～ ${new Date(
              record.rewardEndedat
            ).toLocaleDateString()}`
          }
        />
        <YenField source="totalAmount" label={translate('resources.rewards.fields.totalAmount')} />
        <EnumTextField source="status" translationKeyPrefix={'values.paymentStatus'} />
      </FlexibleListLayout>
    </List>
  );
};

const RewardsShowTitle = () => {
  const translate = useTranslate();
  return <span>{translate('resources.rewards.detailName')}</span>;
};

export const RewardsShow = () => {
  const translate = useTranslate();
  return (
    <Show title={<RewardsShowTitle />}>
      <SimpleShowLayout>
        <DateField showtime={true} source="paymentDate" label={translate('resources.rewards.fields.paymentDate')} />
        <FunctionField
          label={translate('resources.rewards.fields.rewardPeriod')}
          render={(record: { rewardStartedAt: string; rewardEndedat: string }) =>
            `${new Date(record.rewardStartedAt).toLocaleDateString()} ～ ${new Date(
              record.rewardEndedat
            ).toLocaleDateString()}`
          }
        />
        <YenField source="rewardAmount" label={translate('resources.rewards.fields.rewardAmount')} />
        <YenField source="administrativeFee" label={translate('resources.rewards.fields.administrativeFee')} />
        <YenField source="salesTax" label={translate('resources.rewards.fields.salesTax')} />
        <YenField source="totalAmount" label={translate('resources.rewards.fields.totalAmount')} />
        <EnumTextField source="status" translationKeyPrefix={'values.paymentStatus'} />
      </SimpleShowLayout>
    </Show>
  );
};
