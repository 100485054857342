import React, { useEffect, useState, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

/**
 * useFormValidation カスタムフック
 * 必須フィールドがすべて入力されているかどうかを判定する
 * @param requiredFields 必須フィールドの名前の配列
 * @returns isFormValid - フォームが有効かどうかのブール値
 */
export const useFormValidation = (requiredFields: string[]): boolean => {
  const formContext = useFormContext();

  // control が定義されていない場合でも useWatch を呼び出すため、空のデフォルト値を用意
  const { control } = formContext || {};

  // control が null の場合でも useWatch を呼び出すことで、Hooks が一貫した順序で呼ばれるようにする
  const values = useWatch({ control, name: requiredFields }) || [];

  const [isFormValid, setIsFormValid] = useState(false);

  // 必要なフィールドがすべて入力されているか確認する関数
  const allFieldsFilled = useMemo(() => {
    return requiredFields.every((_, index) => {
      const value = values[index];
      if (value === undefined || value === null) return false;
      if (typeof value === 'string') return value.trim() !== '';
      return value !== '';
    });
  }, [values, requiredFields]); // values と requiredFields を依存関係として useMemo を使用

  useEffect(() => {
    setIsFormValid(allFieldsFilled);
  }, [allFieldsFilled]);

  return isFormValid;
};

/**
 * FormValidation コンポーネント
 * 必須フィールドのバリデーションを行い、その結果を children 関数に渡す
 */
const FormValidation: React.FC<{ requiredFields: string[]; children: (isFormValid: boolean) => React.ReactNode }> = ({
  requiredFields,
  children
}) => {
  // フォームバリデーションを実行
  const isFormValid = useFormValidation(requiredFields);

  return <>{children(isFormValid)}</>;
};

export default FormValidation;
