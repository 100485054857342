import React, { useState } from 'react';
import { TextInput } from 'react-admin';
import { IconButton, InputAdornment, FormHelperTextProps } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface PasswordInputWithToggleProps {
  source: string;
  label: string;
  validate?: any;
  required?: boolean;
  helperText?: string;
  FormHelperTextProps?: FormHelperTextProps;
}

const PasswordInputWithToggle: React.FC<PasswordInputWithToggleProps> = ({
  source,
  label,
  validate,
  helperText,
  FormHelperTextProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextInput
      source={source}
      label={label}
      validate={validate}
      helperText={helperText}
      FormHelperTextProps={FormHelperTextProps}
      sx={{ marginBottom: 0 }}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      fullWidth
    />
  );
};

export default PasswordInputWithToggle;
