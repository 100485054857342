import {
  AuthenticationDetails,
  CodeDeliveryDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

class ForgotPasswordResult {
  isSendCode: boolean = false;
  codeDeliveryDetails: CodeDeliveryDetails | null = null;
}

class LoginResult {
  cognitoUserSession: CognitoUserSession | null = null;
}

class AuthHelper {
  private static endpoint: string = '';
  private static userPoolId: string = '';
  private static clientId: string = '';

  static init(endpoint: string, userPoolId: string, clientId: string) {
    AuthHelper.endpoint = endpoint;
    AuthHelper.userPoolId = userPoolId;
    AuthHelper.clientId = clientId;
  }

  private static createUserPool() {
    const userPool = new CognitoUserPool({
      endpoint: this.endpoint,
      UserPoolId: this.userPoolId,
      ClientId: this.clientId
    });
    return userPool;
  }

  private static createUser(username: string) {
    return new CognitoUser({
      Username: username,
      Pool: AuthHelper.createUserPool()
    });
  }

  public static login(username: string, password: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      // 空白時はユーザー認証エラーを出す。
      if (username == '' || password == '') {
        reject({
          code: 'NotAuthorizedException'
        });
        return;
      }

      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = AuthHelper.createUser(username);

      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const obj = new LoginResult();
          obj.cognitoUserSession = result;
          resolve(obj);
        },

        onFailure: (err) => {
          console.error(err);
          reject(err);
        },

        // eslint-disable-next-line no-unused-vars
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log('New password is required');
          reject('New password is required');
        }
      });
    });
  }

  public static forgotPassword(username: string): Promise<ForgotPasswordResult> {
    return new Promise((resolve, reject) => {
      const cognitoUser = AuthHelper.createUser(username);

      cognitoUser.forgotPassword({
        onSuccess: function () {
          // successfully initiated reset password request
          //console.log('CodeDeliveryData from forgotPassword: ' + data);
          resolve({
            isSendCode: false,
            codeDeliveryDetails: null
          });
        },
        onFailure: function (err) {
          reject(err);
        },
        //Optional automatic callback
        inputVerificationCode: function (data) {
          //console.log('Code sent to: ' + data);
          resolve({
            isSendCode: true,
            codeDeliveryDetails: data
          });
        }
      });
    });
  }

  public static setNewPassword(username: string, verificationCode: string, newPassword: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const cognitoUser = AuthHelper.createUser(username);
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess(data: any) {
          console.log('Password confirmed!');
          resolve(data);
        },
        onFailure(err) {
          console.log('Password not confirmed!');
          reject(err);
        }
      });
    });
  }

  public static getErrorKeyCode(err: any): string {
    if (typeof err === 'object') {
      const code = (err as any).code;
      const knownCodes = [
        'MeEndpointException', // 自分自身のデータが取得できなかった(Coginitoのエラーではない)
        'NotAuthorizedException', // ユーザー名もしくはパスワードが間違っている
        'UserNotFoundException', // ユーザーが見つからない
        'CodeMismatchException', // 検証コードが一致しない
        'UsernameExistsException', // ユーザーが既に存在する
        'InvalidPasswordException', // パスワード要件が満たされてない。
        'ExpiredCodeException', // 検証コードが期限切れ
        'LimitExceededException' // 指定以上の回数のリクエストがあった
      ];
      if (knownCodes.includes(code)) {
        return 'cognito.' + code;
      }
    }
    return 'cognito.Unknown';
  }
}

export default AuthHelper;
