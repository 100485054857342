import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setRegisterToken,
  setRegisterFormData,
  setRoleFlag,
  getRoleFlag,
  getInviteCode as getECSiteInviteCode,
  extractRoleFlag,
  getPartnerInviteCode,
  setInviteSrcData
} from './Common';
import dataProvider from '../../dataProvider';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let inviteCode: string | null = null;
    let inviteSrc: number | null = null;
    let inviteSite: string | null = null;

    const token = setRegisterToken(location.search);
    console.log(token);

    if (!token) {
      const role = extractRoleFlag(location.pathname);
      if (role !== 'partner') {
        navigate(`/register/partner/error`, { replace: true });
        return;
      }
      let code = getECSiteInviteCode(location.search);
      if (code) {
        inviteCode = code;
        inviteSrc = 2;
      } else {
        code = getPartnerInviteCode(location.search);
        if (code) {
          inviteCode = code;
          inviteSrc = 1;
        } else {
          navigate(`/register/partner/error`, { replace: true });
          return;
        }
      }
    } else {
      inviteSrc = 2;
    }

    setRoleFlag(location.pathname);

    const checkToken = async (requestToken: string | null) => {
      try {
        const role = getRoleFlag();
        if (!role) {
          navigate(`/register/partner/error`, { replace: true });
          return;
        }

        let token: string;
        if (inviteCode !== null) {
          const response = await dataProvider.register(
            { inviteCode: inviteCode, inviteSrc: inviteSrc },
            `registers/${role}s/issue-token`,
            'POST'
          );

          if (!response.data || !response.data.token) {
            navigate(`/register/partner/error`, { replace: true });
            return;
          }

          const { token: issuedToken, clientName } = response.data;
          inviteSite = clientName;

          let newtoken = setRegisterToken(location.search, issuedToken);
          if (!newtoken) {
            navigate(`/register/partner/error`, { replace: true });
            return;
          }
          token = newtoken;
        } else {
          if (!requestToken) {
            navigate(`/register/partner/error`, { replace: true });
            return;
          }
          token = requestToken;
        }

        let endpoint;
        if (role.endsWith('-staff')) {
          endpoint = `registers/${role.split('-')[0]}s/check-staff-token/${token}`;
        } else {
          endpoint = `registers/${role}s/check-token/${token}`;
        }

        const response = await dataProvider.register({}, endpoint, 'GET');
        const { registerStatus, clientName, ...formData } = response.data; // clientName を取得

        // clientName を inviteSite に設定
        inviteSite = clientName;
        setInviteSrcData(inviteSrc, inviteSite);
        setRegisterFormData(formData);
        switch (registerStatus) {
          case 0:
            if (role?.includes('staff')) {
              navigate(`/register/${role}/create-staff`, { replace: true });
            } else {
              navigate(`/register/${role}/start-register`, { replace: true });
            }
            break;
          case 1:
            navigate(`/`, { replace: true });
            break;
          case 2:
          case 3:
            navigate(`/register/${role}/create-staff`, { replace: true });
            break;
          case 4:
          case 5:
          default:
            navigate(`/register/${role}/error`, { replace: true });
            break;
        }
      } catch (error) {
        console.error('Error checking token:', error);
        navigate(`/register/partner/error`, { replace: true });
      }
      setIsLoading(false);
    };

    setIsLoading(true);
    checkToken(token);
  }, [location.search, location.pathname, navigate, isLoading]);

  return null;
};

export default Register;
