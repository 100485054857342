import React from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useTranslate } from 'react-admin';

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  graphTarget: string;
  selectedResultType: number;
}

const CustomGraphTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  graphTarget,
  selectedResultType
}) => {
  const translate = useTranslate();

  const formatNumber = (num: number): string => {
    return num.toLocaleString();
  };

  if (active && payload && payload.length) {
    // 翻訳キーを使用して単位を取得
    const unit = translate(`values.graphTargetUnits.${graphTarget}`);

    if (selectedResultType === 4) {
      // selectedResultTypeが4のとき、合計値を表示
      const totalValue = payload.reduce((acc, data) => acc + (data.value as number), 0);

      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p className="label">{`${label} : ${formatNumber(totalValue)} ${unit}`}</p>
          {payload.map((data, index) => (
            <p key={index} style={{ color: data.color }}>
              {`${translate(`values.graphTargetTypes.${data.name}`)} : ${formatNumber(data.value as number)} ${unit}`}
            </p>
          ))}
        </div>
      );
    }

    if (selectedResultType === 1) {
      // selectedResultTypeが1のとき、salesAmount、feebackCommission、affiliateCommissionを表示
      const { salesAmount, feebackCommission, affiliateCommission } = payload[0].payload;

      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p className="label">{`${label}`}</p>
          <p style={{ color: '#1F77B4' }}>{`${translate('values.graphTargetTypes.salesAmount')} : ${formatNumber(
            salesAmount
          )} ${unit}`}</p>
          <p style={{ color: '#FF7F0E' }}>{`${translate('values.graphTargetTypes.feebackCommission')} : ${formatNumber(
            feebackCommission
          )} ${unit}`}</p>
          <p style={{ color: '#2CA02C' }}>{`${translate(
            'values.graphTargetTypes.affiliateCommission'
          )} : ${formatNumber(affiliateCommission)} ${unit}`}</p>
        </div>
      );
    }
  }

  return null;
};

export default CustomGraphTooltip;
