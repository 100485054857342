import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLogin, useLogout, useNotify } from 'react-admin';
import { Typography, TextField, Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import AuthHelper from '../../utils/AuthHelper';
import SiteLogo from '../SiteLogo';
import RegisterLayout from '../RegisterLayout';
import { ActionButton } from '../ActionButton';
import FormValidation from '../FormValidation';

interface CustomLoginPageProps {
  subtitle?: string;
  backgroundColor?: string;
  buttonColor?: string;
}

interface LoginFormValues {
  username: string;
  password: string;
}

const CustomLoginPage: React.FC<CustomLoginPageProps> = ({ subtitle = '', buttonColor = '' }) => {
  const methods = useForm<LoginFormValues>(); // useForm を使ってフォームの状態を管理し、型を定義
  const { setValue } = methods;
  const logout = useLogout();
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const CustomLink = styled(Link)`
    color: #000000;
    font-size: 16px;
    &:hover {
      color: #808080;
    }
  `;

  // フォーム送信のハンドラー
  const handleSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    try {
      await logout();
      await login(data);
      navigate(`/`, { replace: true });
    } catch (error) {
      notify(AuthHelper.getErrorKeyCode(error), { type: 'error' });
    }
  };

  // 必須フィールドの定義
  const requiredFields = ['username', 'password'];

  return (
    <RegisterLayout>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Stack
          spacing={5}
          sx={{ maxWidth: '600px', width: '100%' }}
          justifyContent="center"
          alignItems="center"
          direction={'column'}
        >
          {/* サイトのロゴとサブタイトル表示 */}
          <Box>
            <SiteLogo />
            <Typography variant="body1" align="center">
              {subtitle}
            </Typography>
          </Box>

          {/* フォーム全体を FormProvider でラップして useForm を提供 */}
          <FormProvider {...methods}>
            <FormValidation requiredFields={requiredFields}>
              {(isFormValid) => (
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                  {/* ユーザー名とパスワード入力 */}
                  <Stack spacing={5}>
                    <Box sx={{ width: '100%' }}>
                      <TextField
                        label="メールアドレス"
                        margin="normal"
                        fullWidth
                        name="username"
                        onChange={(e) => setValue('username', e.target.value)}
                      />

                      <TextField
                        label="パスワード"
                        type="password"
                        margin="normal"
                        fullWidth
                        name="password"
                        onChange={(e) => setValue('password', e.target.value)}
                      />
                    </Box>

                    {/* パスワード忘れリンク */}
                    <Box sx={{ textAlign: 'center' }}>
                      <CustomLink to="/reset-password">パスワードをお忘れの担当者はこちら</CustomLink>
                    </Box>

                    {/* ログインボタン（isFormValid に応じて無効化を制御） */}
                    <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
                      <ActionButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: buttonColor }}
                        disabled={!isFormValid} // フォームが有効な場合にのみボタンを有効化
                      >
                        ログイン
                      </ActionButton>
                    </Box>
                  </Stack>
                </form>
              )}
            </FormValidation>
          </FormProvider>
        </Stack>
      </Box>
    </RegisterLayout>
  );
};

export default CustomLoginPage;
