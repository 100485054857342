export function formatRFC3339DateString(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  // Intl.DateTimeFormatでロケールを指定してフォーマットを適用
  const formatter = new Intl.DateTimeFormat('ja-JP', options);
  const [
    { value: year },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second }
  ] = formatter.formatToParts(date);

  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}
