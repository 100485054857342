export interface Choice {
  id: number;
  name: string;
}

export interface StringChoice {
  id: string;
  name: string;
}

export const AUTHORITY_CHOICES: Choice[] = [
  { id: 1, name: '管理者' },
  { id: 2, name: 'マネージャー' },
  { id: 3, name: '経理' }
];

export const ACCOUNT_STATUS: Choice[] = [
  { id: 0, name: '無効' },
  { id: 1, name: '有効' },
  { id: 2, name: '仮登録' }
];

export const PARTNER_TYPE: Choice[] = [
  { id: 1, name: '個人' },
  { id: 2, name: '法人' }
];

export const CLIENT_MANAGEMENT_CHOICES: Choice[] = [
  { id: 1, name: '全て' },
  { id: 2, name: '個別管理' }
];

export const ACCOUNT_STATUS_CLIENT: Choice[] = [
  { id: 1, name: '有効' },
  { id: 0, name: '無効' }
];

export const INVALD_FLAG: Choice[] = [
  { id: 1, name: '有効' },
  { id: 0, name: '無効' }
];

export const CLOSING_MONTH: Choice[] = [
  { id: 0, name: '当月' },
  { id: 1, name: '翌月' },
  { id: 2, name: '翌々月' }
];

export const EC_SITE_TYPE: Choice[] = [
  { id: 1, name: 'Shopify' }
  //現在はShopifyのみ選択可
  // { id: 99, name: 'その他' }
];

export const BANK_ACCOUNT_TYPE: Choice[] = [
  { id: 1, name: '普通' },
  { id: 2, name: '当座' }
];

export const ANALYSIS_DATE_TYPE: Choice[] = [
  { id: 1, name: '月' },
  { id: 2, name: '日' }
];

export const ANALYSIS_RESULT_TYPE: Choice[] = [
  { id: 1, name: '全体' },
  { id: 2, name: 'ECサイト' },
  { id: 3, name: 'パートナー' }
  // { id: 4, name: 'アフィリエイト' }
];

export const ANALYSIS_GRAPH_TARGET_TYPE: StringChoice[] = [
  { id: 'salesAmount', name: '売上(税抜)' },
  { id: 'salesCount', name: '売上件数' },
  { id: 'clickCount', name: '訪問数' }
];

export const REGISTER_ROLE: StringChoice[] = [
  { id: 'admin', name: 'FEEBACK管理者' },
  { id: 'client', name: 'ECサイト管理者' },
  { id: 'partner', name: 'パートナー管理者' },
  { id: 'admin-staff', name: 'FEEBACKスタッフ' },
  { id: 'client-staff', name: 'ECサイトスタッフ' },
  { id: 'partner-staff', name: 'パートナースタッフ' }
];

export const CLOSING_DATE: Choice[] = [
  { id: 1, name: '1日' },
  { id: 2, name: '2日' },
  { id: 3, name: '3日' },
  { id: 4, name: '4日' },
  { id: 5, name: '5日' },
  { id: 6, name: '6日' },
  { id: 7, name: '7日' },
  { id: 8, name: '8日' },
  { id: 9, name: '9日' },
  { id: 10, name: '10日' },
  { id: 11, name: '11日' },
  { id: 12, name: '12日' },
  { id: 13, name: '13日' },
  { id: 14, name: '14日' },
  { id: 15, name: '15日' },
  { id: 16, name: '16日' },
  { id: 17, name: '17日' },
  { id: 18, name: '18日' },
  { id: 19, name: '19日' },
  { id: 20, name: '20日' },
  { id: 21, name: '21日' },
  { id: 22, name: '22日' },
  { id: 23, name: '23日' },
  { id: 24, name: '24日' },
  { id: 25, name: '25日' },
  { id: 26, name: '26日' },
  { id: 27, name: '27日' },
  { id: 28, name: '28日' },
  { id: 29, name: '29日' },
  { id: 30, name: '30日' },
  { id: 99, name: '月末' }
];

export const PAYMENT_STATUS: Choice[] = [
  { id: 1, name: '未入金' },
  { id: 2, name: '入金済み' }
];

export const FEEBACK_PAYMENT_STATUS: Choice[] = [
  { id: 1, name: '支払い不要' },
  { id: 2, name: '未振込' },
  { id: 3, name: '振込済み' },
  { id: 4, name: 'エラー' }
];

export const APPLY_STATUS: Choice[] = [
  { id: 1, name: '未承認' },
  { id: 2, name: '承認済み' },
  { id: 3, name: '却下' }
];

export const PREFECTURE_LIST: StringChoice[] = [
  { id: '北海道', name: '北海道' },
  { id: '青森県', name: '青森県' },
  { id: '岩手県', name: '岩手県' },
  { id: '宮城県', name: '宮城県' },
  { id: '秋田県', name: '秋田県' },
  { id: '山形県', name: '山形県' },
  { id: '福島県', name: '福島県' },
  { id: '茨城県', name: '茨城県' },
  { id: '栃木県', name: '栃木県' },
  { id: '群馬県', name: '群馬県' },
  { id: '埼玉県', name: '埼玉県' },
  { id: '千葉県', name: '千葉県' },
  { id: '東京都', name: '東京都' },
  { id: '神奈川県', name: '神奈川県' },
  { id: '新潟県', name: '新潟県' },
  { id: '富山県', name: '富山県' },
  { id: '石川県', name: '石川県' },
  { id: '福井県', name: '福井県' },
  { id: '山梨県', name: '山梨県' },
  { id: '長野県', name: '長野県' },
  { id: '岐阜県', name: '岐阜県' },
  { id: '静岡県', name: '静岡県' },
  { id: '愛知県', name: '愛知県' },
  { id: '三重県', name: '三重県' },
  { id: '滋賀県', name: '滋賀県' },
  { id: '京都府', name: '京都府' },
  { id: '大阪府', name: '大阪府' },
  { id: '兵庫県', name: '兵庫県' },
  { id: '奈良県', name: '奈良県' },
  { id: '和歌山県', name: '和歌山県' },
  { id: '鳥取県', name: '鳥取県' },
  { id: '島根県', name: '島根県' },
  { id: '岡山県', name: '岡山県' },
  { id: '広島県', name: '広島県' },
  { id: '山口県', name: '山口県' },
  { id: '徳島県', name: '徳島県' },
  { id: '香川県', name: '香川県' },
  { id: '愛媛県', name: '愛媛県' },
  { id: '高知県', name: '高知県' },
  { id: '福岡県', name: '福岡県' },
  { id: '佐賀県', name: '佐賀県' },
  { id: '長崎県', name: '長崎県' },
  { id: '熊本県', name: '熊本県' },
  { id: '大分県', name: '大分県' },
  { id: '宮崎県', name: '宮崎県' },
  { id: '鹿児島県', name: '鹿児島県' },
  { id: '沖縄県', name: '沖縄県' }
];

export function getChoiceName(list: Choice[], id: number): string | null {
  const choice = list.find((v) => v.id === id);
  return choice ? choice.name : null;
}
