import { alpha, SxProps, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { RadioButtonGroupInput, RadioButtonGroupInputProps, useInput } from 'react-admin';

interface NumericSelectRadioButtonGroupInputProps extends Omit<RadioButtonGroupInputProps, 'source'> {
  source: string;
  icons?: React.ReactNode[];
  hiddenLabel?: boolean;
  sx?: SxProps;
}

const NumericSelectRadioButtonGroupInput: React.FC<NumericSelectRadioButtonGroupInputProps> = (props) => {
  const { field } = useInput(props);
  const handleOnChange = (...event: any[]) => {
    const value = parseInt(event[1], 10);
    setSelectedValue(value);

    field.onChange(value);
  };
  const [selectedValue, setSelectedValue] = useState(0);
  const theme = useTheme();
  const selectedIndex: number | undefined =
    (props.choices ?? []).findIndex((choice) => choice.id === selectedValue) + 1 || undefined;

  const hiddenSx = props.hiddenLabel
    ? {
        mt: 0,
        '& .RaRadioButtonGroupInput-label': {
          display: 'none'
        }
      }
    : {};

  return (
    <RadioButtonGroupInput
      {...props}
      sx={{
        width: '100%',
        '& .MuiFormGroup-root': {
          width: '100%',
          mt: props.hiddenLabel ? 0 : 1,
          p: 0,
          gap: '8px'
        },
        '& .Mui-error.MuiFormControlLabel-root': {
          borderColor: theme.palette.error.main
        },
        '& .MuiFormControlLabel-root': {
          backgroundColor: '#00000000',
          border: '2px solid',
          borderRadius: '4px',
          borderColor: theme.palette.primary.main,
          ml: 0,
          mr: '1px',
          pr: 2,
          pl: 1,
          pt: 0.5,
          pb: 0.5,
          flex: '1'
        },
        [`& .MuiFormControlLabel-root:nth-of-type(${selectedIndex})`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.16)
        },
        ...hiddenSx,
        ...props.sx
      }}
      onChange={handleOnChange}
      optionText={
        props.icons &&
        ((item) => {
          if (!props.icons) {
            return undefined;
          }
          const index = (props.choices ?? []).findIndex((choice) => choice.id === item.id);
          if (index === -1) {
            return undefined;
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.icons[item.id - 1]}
              <span style={{ marginLeft: '6px' }}>{item.name}</span>
            </div>
          );
        })
      }
    />
  );
};

export default NumericSelectRadioButtonGroupInput;
