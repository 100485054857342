import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslate } from 'react-admin';

interface RegisterHeaderProgressProps {
  icon: JSX.Element;
  title: string;
  step: number;
  totalSteps: number;
}

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const IconLabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1)
}));

const RegisterHeaderProgress: React.FC<RegisterHeaderProgressProps> = ({ icon, title, step, totalSteps }) => {
  const theme = useTheme();
  const translate = useTranslate();
  const iconSize = '32px';

  return (
    <Box>
      <ProgressContainer>
        {/* <LinearProgress
          variant="determinate"
          value={100}
          sx={{
            height: 8,
            mb: 4,
            backgroundColor: '#F5F5F5'
          }}
        /> */}
      </ProgressContainer>
      <HeaderContainer sx={{ pt: 0, pr: 0, pb: 1, pl: 0 }}>
        <IconLabelContainer>
          {React.cloneElement(icon, {
            style: { marginRight: theme.spacing(1), fontSize: iconSize }
          })}
          <Typography variant="h4">{translate(title)}</Typography>
        </IconLabelContainer>
      </HeaderContainer>
    </Box>
  );
};

export default RegisterHeaderProgress;
