import React, { useEffect, useState } from 'react';
import { Box, Button, Link, ButtonProps, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { SimpleForm, TextInput, required, useTranslate, SelectInput, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import NumericSelectRadioButtonGroupInput from '../common_modules/components/NumericSelectRadioButtonGroupInput';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import { BANK_ACCOUNT_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import { getRegisterFormData, getRegisterToken, setRegisterFormData } from '../common_modules/components/Common';
import dataProvider from '../dataProvider';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import LinkButton from '../common_modules/components/LinkButton';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#000000'
}));

const AddressButton = styled(Button)(({ theme }) => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

interface RegisterFormData {
  partnerType?: number;
  [key: string]: any;
}

const CustomToolbar = ({ loading }: { loading: boolean }) => (
  <Stack alignItems="center" spacing={3} mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      次へ
    </RegisterSubmitButton>
    <LinkButton href="/register/partner/contract">{'< パートナー契約確認に戻る'}</LinkButton>
  </Stack>
);

const FormContents: React.FC = () => {
  const translate = useTranslate();
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setPartnerType(formData.partnerType);
    }
  }, []);

  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  return (
    <Box sx={{ maxWidth: '600px', width: '100%' }}>
      {partnerType !== 1 && (
        <TextInput
          source="name"
          label={translate('resources.registers.fields.name')}
          validate={required()}
          disabled={partnerType !== 2}
        />
      )}
      <TextInput
        source="lastName"
        label={
          partnerType !== 1
            ? translate('resources.registers.fields.picLastName')
            : translate('resources.registers.fields.lastName')
        }
        validate={required()}
        helperText={'resources.registers.messages.canNotChanged'}
      />
      <TextInput
        source="firstName"
        label={
          partnerType !== 1
            ? translate('resources.registers.fields.picFirstName')
            : translate('resources.registers.fields.firstName')
        }
        validate={required()}
        helperText={'resources.registers.messages.canNotChanged'}
      />
      <Stack direction="row" spacing={isSmallScreen ? 2 : 3}>
        <Box sx={{ width: isSmallScreen ? '100%' : undefined }}>
          <TextInput
            source="zipCode"
            label={translate('resources.registers.fields.zipCode')}
            validate={required()}
            helperText={'ハイフンなし'}
          />
        </Box>
        <AddressButton variant="contained" color="primary" onClick={handleGetAddress}>
          住所を入力
        </AddressButton>
      </Stack>
      <SelectInput
        source="prefecture"
        validate={required()}
        choices={PREFECTURE_LIST}
        label={translate('resources.registers.fields.prefecture')}
      />
      <TextInput source="city" label={translate('resources.registers.fields.city')} validate={required()} />
      <TextInput source="address1" label={translate('resources.registers.fields.address1')} validate={required()} />
      <TextInput source="address2" label={translate('resources.registers.fields.address2')} />
      {partnerType === 2 && (
        <TextInput
          source="tel"
          label={translate('resources.registers.fields.tel')}
          validate={partnerType === 2 ? required() : undefined}
          helperText={'ハイフンなし'}
        />
      )}
      {partnerType === 1 && (
        <TextInput
          source="mobilePhone"
          label={translate('resources.registers.fields.mobilePhone')}
          validate={partnerType === 1 ? required() : undefined}
          helperText={'ハイフンなし'}
        />
      )}
      <TextInput source="email" label={translate('resources.registers.fields.email')} validate={required()} />
      <TextInput
        source="emailConfirm"
        label={translate('resources.registers.fields.emailConfirm')}
        validate={required()}
      />
      <TextInput source="website" label={translate('resources.registers.fields.website')} />
      <TextInput
        source="invoiceNumber"
        label={translate('resources.registers.fields.invoiceNumber')}
        helperText={'T+半角数字13桁'}
      />
      {partnerType !== 1 && (
        <TextInput
          source="dunsNumber"
          label={translate('resources.registers.fields.dunsNumber')}
          validate={required()}
          disabled={partnerType !== 2}
          helperText={
            <span>
              D-U-N-Sナンバーの確認は
              <Link href="https://duns-number-jp.tsr-net.co.jp/search/jpn/find_jpn.asp" target="_blank" rel="noopener">
                こちら
              </Link>
              から。
            </span>
          }
        />
      )}
      <CustomDivider sx={{ my: 2 }} />
      <TextInput
        source="bankNumber"
        label={translate('resources.registers.fields.bankNumber')}
        validate={required()}
        helperText={'半角数字4桁'}
      />
      <TextInput source="bankName" label={translate('resources.registers.fields.bankName')} validate={required()} />
      <TextInput
        source="bankBranchNumber"
        label={translate('resources.registers.fields.bankBranchNumber')}
        validate={required()}
        helperText={'半角数字3桁'}
      />
      <TextInput
        source="bankBranchName"
        label={translate('resources.registers.fields.bankBranchName')}
        validate={required()}
      />
      <NumericSelectRadioButtonGroupInput
        source="bankAccountType"
        choices={BANK_ACCOUNT_TYPE}
        label={translate('resources.registers.fields.bankAccountType')}
        validate={required()}
      />
      <TextInput
        source="bankAccountNumber"
        label={translate('resources.registers.fields.bankAccountNumber')}
        validate={required()}
        fullWidth
        helperText={'半角数字7桁'}
      />
      <TextInput
        source="bankAccountName"
        label={translate('resources.registers.fields.bankAccountName')}
        validate={required()}
        helperText={'カナ（小文字を除く）、英大文字、数字、半角スペース、記号（().-）のみ使用できます。'}
        FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
      />
    </Box>
  );
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<RegisterFormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerType, setPartnerType] = useState<number | undefined>(undefined);

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/partner/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setDefaultValues(formData);
      setPartnerType(formData.partnerType);
    }
  }, []);

  const handleSubmit = async (data: RegisterFormData) => {
    setIsLoading(true);
    try {
      if (!data.address2) {
        data.address2 = '';
      }

      const sendingFormData = {
        token: getRegisterToken(),
        ...data,
        partnerType
      };

      const registerPartnerUrl = `/registers/partners/create-test`;
      await dataProvider.register(sendingFormData, registerPartnerUrl);

      setRegisterFormData(sendingFormData);
      navigate(`/register/partner/confirm`);
      // if (data.partnerType === 1) {
      //   navigate(`/register/partner/upload-id`);
      // } else if (data.partnerType === 2) {
      //   navigate(`/register/partner/confirm`);
      // }
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterLayout>
      <Container>
        <Stack spacing={1}>
          <Helmet>
            <title>{translate('resources.registers.name.Form')}</title>
          </Helmet>
          <RegisterHeaderProgress
            title={translate('resources.registers.name.Form')}
            icon={<HandshakeOutlinedIcon />}
            step={3}
            totalSteps={7}
          />
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
                maxWidth: '600px'
              }}
            >
              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} />}
                sx={{
                  p: 0,
                  '& .MuiTextField-root': { width: '100%' }
                }}
                onSubmit={handleSubmit}
                gap={3}
              >
                <FormContents />
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Form;
