import React, { useEffect, useState } from 'react';
import { Container, Typography, Link, Box, useMediaQuery, Stack } from '@mui/material';
import { SimpleForm, required, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import NumericSelectRadioButtonGroupInput from '../common_modules/components/NumericSelectRadioButtonGroupInput';
import { PARTNER_TYPE } from '../common_modules/constants/choices';
import { getInviteSrcData, setRegisterFormData } from '../common_modules/components/Common';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import SiteLogo from '../common_modules/components/SiteLogo';
import Divider from '@mui/material/Divider';

const steps = [
  '利用契約',
  'パートナー契約',
  '登録情報入力',
  // '身分証明書提出',
  '登録内容確認',
  '申請',
  '審査',
  '承認',
  '登録完了'
];

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#000000'
}));

const StartRegistration: React.FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // 各フォームの状態を個別に管理
  const [partnerTypeUpper, setPartnerTypeUpper] = useState<number | undefined>(undefined);
  const [partnerTypeLower, setPartnerTypeLower] = useState<number | undefined>(undefined);
  const [inviteSrc, setInviteSrc] = useState<number | null>(null);
  const [inviteSite, setInviteSite] = useState<string | null>(null);

  // ページ遷移時にセッションストレージのトークンをチェック
  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/partner/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const data = getInviteSrcData();
    setInviteSrc(data);
    const site = sessionStorage.getItem('inviteSite');
    setInviteSite(site);
  }, []);

  // 上部フォームの「次へ」ボタンが押された時
  const handleNextUpper = () => {
    setRegisterFormData({ partnerType: partnerTypeUpper });
    navigate('/register/partner/terms');
  };

  // 下部フォームの「次へ」ボタンが押された時
  const handleNextLower = () => {
    setRegisterFormData({ partnerType: partnerTypeLower });
    navigate('/register/partner/terms');
  };

  const gap = isSmallScreen ? 3 : 5;

  return (
    <RegisterLayout>
      <Container>
        <Stack direction={'column'} gap={gap}>
          <Helmet>
            <title>パートナー登録</title>
          </Helmet>
          {inviteSite ? (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="h2">{inviteSite}</Typography>
              <Typography variant="body1">{inviteSite ? `Powered by FEEBACK` : ''}</Typography>
            </Box>
          ) : (
            <SiteLogo />
          )}
          <Typography variant="h4">パートナー登録</Typography>
          {/* 上部フォーム */}
          <Typography variant="body1">はじめに、登録するアカウント種別を選択してください。</Typography>
          <Box sx={!isSmallScreen ? { display: 'flex', justifyContent: 'center' } : {}}>
            <SimpleForm
              toolbar={false}
              onSubmit={handleNextUpper}
              component={Box}
              sx={{ width: isSmallScreen ? '100%' : '600px' }}
            >
              <NumericSelectRadioButtonGroupInput
                source="partnerTypeUpper"
                choices={PARTNER_TYPE}
                label={translate('resources.registers.fields.partnerType')}
                icons={[<PersonOutlineOutlinedIcon key="person" />, <BusinessOutlinedIcon key="business" />]}
                validate={required()}
                onChange={(value: any) => setPartnerTypeUpper(value)}
                optionText="name"
                optionValue="id"
                hiddenLabel={true}
                row={!isSmallScreen}
                sx={{
                  mb: 0,
                  '& .MuiFormHelperText-root': { display: 'none' }
                }}
              />
            </SimpleForm>
          </Box>
          <Box display="flex" justifyContent="center">
            <RegisterSubmitButton onClick={handleNextUpper} disabled={partnerTypeUpper === undefined}>
              次へ
            </RegisterSubmitButton>
          </Box>

          {isSmallScreen ? (
            <Typography variant="body1">
              FEEBACKのパートナー登録を行います。登録に必要な情報をお確かめの上、登録を進めてください。
              <br />
              登録完了までは、個人登録の場合は7ステップ、法人登録の場合は6ステップあります。
              <br />
              途中「パートナー登録」「身分証明書提出(※個人選択時のみ)」は、次の画面へ進んだ段階で、そこまでの手続き内容が保存され、離脱後も次のステップから再開できます。{' '}
            </Typography>
          ) : (
            <Typography variant="body1">
              FEEBACKのパートナー登録を行います。登録に必要な情報をお確かめの上、ステップを進めてください。
            </Typography>
          )}

          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ maxWidth: isSmallScreen ? '396px' : '684px', width: '100%' }}
            >
              {steps.map((label, index) => (
                <React.Fragment key={label}>
                  {isSmallScreen ? (
                    <Box component="img" src={`/images/img_step_sp${index + 1}.svg`} alt={`step${index + 1}`} />
                  ) : (
                    <Box component="img" src={`/images/img_step_pc${index + 1}.svg`} alt={`step${index + 1}`} />
                  )}
                  {/* 小さい画面では非表示 */}
                  {!isSmallScreen && index < steps.length - 1 && (
                    <Box
                      component="img"
                      src={`/images/step_arrow.svg`}
                      alt={'arrow'}
                      sx={{ width: '24px', height: '24px', padding: '4px 3px' }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>

          <CustomDivider />

          <Stack direction={'column'} gap={2}>
            <Typography variant="h5">振込/支払口座</Typography>
            <Typography variant="body1">
              サービス使用料や手数料の支払い、報酬の振込に使用する口座情報をご登録ください。
            </Typography>
          </Stack>

          <Stack direction={'column'} gap={2}>
            <Typography variant="h5">インボイス登録番号</Typography>
            <Typography variant="body1">
              インボイス制度にご登録済みの方は、T+13桁のインボイス登録番号をご登録ください。(登録は必須ではございません。)
            </Typography>
          </Stack>

          {/* <Stack direction={'column'} gap={2}>
            <Typography variant="h5">身分証明書(個人登録の方)</Typography>
            <Typography variant="body1">
              個人登録の方は、マイナンバーカード、運転免許証などの身分証明書の、表と裏の両面をスキャンまたは撮影して提出してください。
              <br />
              対応形式：JPEG
              <br />
              ファイルサイズ：10MB以下
              <br />
            </Typography>
          </Stack> */}

          <Stack direction={'column'} gap={2}>
            <Typography variant="h5">D-U-N-S番号(法人登録の方)</Typography>
            <Typography variant="body1">
              法人登録の方は、取得済みのD-U-N-S番号をご登録ください。
              <br />
              D-U-N-Sナンバーの確認は{' '}
              <Link href="https://duns-number-jp.tsr-net.co.jp/search/jpn/find_jpn.asp" target="_blank" rel="noopener">
                こちら
              </Link>
              から。
            </Typography>
          </Stack>
          {/* 下部フォーム */}
          <Typography variant="body1">はじめに、登録するアカウント種別を選択してください。</Typography>
          <Box sx={!isSmallScreen ? { display: 'flex', justifyContent: 'center' } : {}}>
            <SimpleForm
              toolbar={false}
              onSubmit={handleNextLower}
              component={Box}
              sx={{ width: isSmallScreen ? '100%' : '600px' }}
            >
              <NumericSelectRadioButtonGroupInput
                source="partnerTypeLower"
                choices={PARTNER_TYPE}
                label={translate('resources.registers.fields.partnerType')}
                icons={[<PersonOutlineOutlinedIcon key="person" />, <BusinessOutlinedIcon key="business" />]}
                validate={required()}
                onChange={(value: any) => setPartnerTypeLower(value)}
                optionText="name"
                optionValue="id"
                hiddenLabel={true}
                row={!isSmallScreen}
                sx={{
                  mb: 0,
                  '& .MuiFormHelperText-root': { display: 'none' }
                }}
              />
            </SimpleForm>
          </Box>
          <Box display="flex" justifyContent="center">
            <RegisterSubmitButton onClick={handleNextLower} disabled={partnerTypeLower === undefined}>
              次へ
            </RegisterSubmitButton>
          </Box>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default StartRegistration;
