// ForgotPasswordPage.tsx
import React, { useState } from 'react';
import { useNotify, required, SimpleForm, TextInput } from 'react-admin';
import { Typography, TextField, Box, Stack } from '@mui/material';
import AuthHelper from '../../utils/AuthHelper';
import { useNavigate } from 'react-router-dom';
import RegisterLayout from '../RegisterLayout';
import { ActionButton } from '../ActionButton';
import PasswordInputWithToggle from '../PasswordInputWithToggle';
import RegisterSubmitButton from '../RegisterSubmitButton';

interface ForgotPasswordPageProps {
  backgroundColor?: string;
  buttonColor?: string;
}

const CustomToolbar = ({ loading }: { loading: boolean }) => (
  <Box display="flex" justifyContent="center" mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      パスワードをリセット
    </RegisterSubmitButton>
  </Box>
);

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ buttonColor = '#BA7967' }) => {
  const [username, setUsername] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const notify = useNotify();

  const handleSendCode = async (event: any) => {
    event.preventDefault();

    try {
      const result = await AuthHelper.forgotPassword(username);
      if (result.isSendCode) {
        setStep(2);
      } else {
        navigate('/reset-complete');
      }
    } catch (err) {
      notify(AuthHelper.getErrorKeyCode(err), { type: 'error' });
    }
  };

  const handleResetPassword = async (data: any) => {
    try {
      const code = data.code;
      const newPassword = data.newPassword;
      await AuthHelper.setNewPassword(username, code, newPassword);
      navigate('/reset-complete');
    } catch (err) {
      const errorCode = (err as { code?: string }).code;
      if (errorCode === 'InvalidParameterException') {
        (err as { code?: string }).code = 'InvalidPasswordException';
      }
      notify(AuthHelper.getErrorKeyCode(err), { type: 'error' });
      return false;
    }
  };

  return (
    <RegisterLayout>
      <Box display="flex" alignItems="center" justifyContent="center">
        {step === 1 ? (
          <Stack
            component="form"
            onSubmit={handleSendCode}
            noValidate
            sx={{ maxWidth: '600px', width: '100%' }}
            spacing={5}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
          >
            <Typography variant="h5" gutterBottom align="center">
              パスワードリセット
            </Typography>

            <TextField
              label="メールアドレス"
              margin="normal"
              value={username}
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              required
            />

            <ActionButton
              variant="contained"
              type="submit"
              disabled={!username} // メールアドレスが入力されていないと無効化
              sx={{
                backgroundColor: !username ? 'lightgray' : buttonColor // 無効時の背景色を変更
              }}
            >
              コードを送信
            </ActionButton>
          </Stack>
        ) : (
          <Stack sx={{ maxWidth: '600px', width: '100%' }} spacing={5}>
            <Typography variant="h5" gutterBottom align="center">
              パスワードリセット
            </Typography>

            <SimpleForm
              toolbar={<CustomToolbar loading={isLoading} />}
              sx={{ maxWidth: '600px', width: '100%' }}
              onSubmit={handleResetPassword}
              gap={3}
            >
              <TextInput source="code" label="認証コード" fullWidth autoComplete="off" validate={required()} />

              <PasswordInputWithToggle
                source="newPassword"
                label={'新しいパスワード'}
                validate={required()}
                helperText="パスワードは8文字以上で、数字、記号、大文字、小文字をそれぞれ1つ以上含める必要があります。"
                FormHelperTextProps={{ sx: { whiteSpace: 'normal' } }}
              />
            </SimpleForm>
          </Stack>
        )}
      </Box>
    </RegisterLayout>
  );
};

export default ForgotPasswordPage;
