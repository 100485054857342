import { Box, Button, Typography, Container, Stack } from '@mui/material';
import React, { useState } from 'react';
import { SimpleForm, TextInput, required, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import dataProvider from '../../dataProvider';
import useErrorHandler from '../hooks/useErrorHandler';
import { getRegisterToken, getRegisterFormData, getRoleFlag } from './Common';
import { Helmet } from 'react-helmet';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import RegisterHeaderProgress from './RegisterHeaderProgress';
import RegisterLayout from './RegisterLayout';
import RegisterSubmitButton from './RegisterSubmitButton';
import LinkButton from './LinkButton';

const CustomToolbar = ({ loading, baseRoleName }: { loading: boolean; baseRoleName: string }) => (
  <Stack alignItems="center" spacing={3} mt={5}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      認証
    </RegisterSubmitButton>
    <LinkButton href={`/register/${baseRoleName}/create-staff`}>{'< 登録情報入力画面に戻る'}</LinkButton>
  </Stack>
);
const SMSAuthentication: React.FC = () => {
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const navigate = useNavigate();
  const roleId = getRoleFlag();
  const [isLoading, setIsLoading] = useState(false);
  const baseRoleName = roleId.split('-')[0];
  const registerFormData = getRegisterFormData();

  const handleResend = async () => {
    let resendFormData: any;
    let apiEndpoint: string;
    const { name, partnerName, email, partnerEmail, userPassword, tel } = registerFormData as { [key: string]: any };

    try {
      if (roleId.includes('staff')) {
        resendFormData = {
          token: getRegisterToken(),
          password: userPassword,
          passwordConfirm: userPassword
        };
        apiEndpoint = `/registers/${baseRoleName}s/create-staff`;
      } else {
        resendFormData = {
          token: getRegisterToken(),
          password: userPassword,
          passwordConfirm: userPassword,
          tel: tel,
          name: partnerName || name,
          email: partnerEmail || email
        };
        apiEndpoint = `/registers/${baseRoleName}s/create-root-staff`;
      }

      await dataProvider.register(resendFormData, apiEndpoint);
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    }
  };

  const handleSubmit = async (data: any) => {
    const { name, partnerName, email, partnerEmail } = registerFormData as { [key: string]: any };
    const requestBody = {
      token: getRegisterToken(),
      name: partnerName || name,
      email: partnerEmail || email,
      confirmationCode: data.confirmationCode // 確認コードを追加
    };

    try {
      setIsLoading(true);
      await dataProvider.register(requestBody, '/registers/partners/create-staff-confirm');
      navigate(`/register/${roleId}/complete`);
    } catch (error) {
      handleError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.SMSAuth')}</title>
        </Helmet>
        <Stack spacing={1}>
          <RegisterHeaderProgress
            title={'resources.registers.name.SMSAuth'}
            icon={<HowToRegOutlinedIcon />}
            step={5}
            totalSteps={7}
          />
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
                maxWidth: '600px'
              }}
            >
              <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<CustomToolbar loading={isLoading} baseRoleName={baseRoleName} />}
                sx={{
                  p: 0
                }}
                gap={2}
              >
                <TextInput
                  source="confirmationCode"
                  label={translate('resources.registers.fields.confirmationCode')}
                  fullWidth
                  validate={required()}
                  helperText=""
                />

                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{
                    width: '100%'
                  }}
                >
                  <Button
                    variant="text"
                    onClick={handleResend}
                    sx={{ textDecoration: 'underline', textTransform: 'none' }}
                  >
                    もう一度認証コードを送る
                  </Button>
                </Box>
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default SMSAuthentication;
